/* @import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poller+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poller+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Italianno&family=Poller+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Italianno&family=Plaster&family=Poller+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plaster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Praise&display=swap");

* {
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
  font-family: "IBM Plex Sans", sans-serif;
  margin: 0px 0px;
}

.star::after {
  content: "*";
  color: red;
  width: 5px;
  height: 5px;
  margin-left: 2px;
}
.dark {
  background-color: black;
}
.sidebar-item {
  transition: color 0.3s ease; /* Add transition for color change */
}

.sidebar-item:hover {
  color: purple; /* Change color on hover */
}

.plaster {
  font-family: "Plaster", system-ui;
  font-weight: 400;
  font-style: normal;
}

.inter {
  font-family: "Inter", system-ui;
}

.praise {
  font-family: "Praise", cursive;
  font-weight: 400;
  font-style: normal;
}

.poller {
  font-family: "Poller One", serif;
  font-weight: 400;
  font-style: normal;
}

.glass {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.italianno {
  font-family: "Italianno", cursive;
}

.roman-list {
  list-style-type: upper-roman;
}

.shape {
  /* background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%); */
  animation: morph 8s ease-in-out infinite;
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  height: 400px;
  transition: all 1s ease-in-out;
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    /* background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%); */
  }

  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
    /* background: linear-gradient(45deg, var(--third) 0%, var(--secondary) 100%); */
  }

  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
    /* background: linear-gradient(45deg, var(--primary) 0%, var(--secondary) 100%); */
  }
}
